import React from "react"
import { Link } from "gatsby"
import Page from "components/page"
import Layout from "components/layout"
import { FadeInUp } from "components/animations/fadeInUp"

const NotFoundPage = (props) => {
    return (
        <Page>
            <Layout {...props} className="u-height-full">
                <section className="u-pd-vt-xxl u-fadeInUp">
                    <div className="l-container u-pd-hz-l">
                        <FadeInUp yOffset={50} delay={100}>
                            <h1 className="c-h1 u-green80 u-uppercase u-semibold u-flex u-flex-center-vt u-mg-bottom-m">
                                Oups !
                            </h1>
                        </FadeInUp>
                        
                        <FadeInUp yOffset={75} delay={200}>
                            <p className="u-fs-m u-lh-l u-mg-bottom-l" style={{ maxWidth: "960px"}}>
                                Il semblerait que cette page ne soit pas prête...
                            </p>
                        </FadeInUp>
                        
                        <FadeInUp yOffset={100} delay={300}>
                            <div className="u-center">
                                <Link to={'/'} className="c-btn c-btn--primary" style={{ minWidth: "240px"}}>
                                    Revenir à l'accueil
                                </Link>
                            </div>
                        </FadeInUp>
                    </div>
                </section>
            </Layout>
        </Page>
    )
}

export default NotFoundPage
